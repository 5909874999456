import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Dialog
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {
  logo,
  loginBg,
  loginLeftLogo,
  mircosoftLogo,
  imgPasswordVisible,
  imgPasswordInVisible,
} from "./assets";
import { MsalProvider, withMsal } from '@azure/msal-react';
import i18n from "i18next"

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Customizable Area Start
import PrivacyPolicy from "../../user-profile-basic/src/PrivacyPolicy.web";
import Loader from "../../../components/src/Loader.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "ClotherRegular",
    h2: {
      fontFamily: "ClotherBold",
      fontSize: "28px",
      lineHeight: "24px",
      fontWeight: "bold",
      marginBottom: "15px",
      color: "#18181E",
    },
    body2: {
      color: "#A1A1A1",
      fontSize: "18px",
      lineHeight: "18px",
    },
    caption: {
      "&::after":
      {
        "position": "absolute",
        "left": "0",
        "right": "0",
        "height": "1px",
        "background": "#DCDCDC",
        "content": "''",
        "top": "50%",
        "transform": "translateY(-50%)"
      }
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        height: "54px",
        border: "1px solid #DCDCDC",
        padding: "15px 15px",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2A29",
        width: "100%",
        fontWeight: 400,
        letterSpacing: "0.5px",
        textOverflow: "ellipsis",
        justifyContent: "flex-start",
        borderRadius: "8px",
        "&.Mui-disabled": {
          cursor: "no-drop",
        },
        "&:hover, &.Mui-focused": {
          border: `1px solid #8735E1`,
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: "ClotherBold",
        textTransform: "none",
        height: "54px",
        border: "none",
        boxShadow: "none",
        width: "100%",
        lineHeight: "22px",
        "&.microsoft-btn": {
          background: "#fff",
          border: "1px solid #DCDCDC",
          marginBottom: "18px",
        },
      },
      contained: {
        fontFamily: "ClotherBold",
        boxShadow: "none",
        background: "#8735E1",
        backgroundColor: "#8735E1",
        borderRadius: "8px",
        fontWeight: 700,
        color: "#fff",
        "&:hover, &:focus": {
          boxShadow: "none",
          background: "#8735E1",
        },
        "&.microsoft-btn": {
          background: "#fff",
          backgroundColor: "#fff",
          color: "#18181E",
          "&:hover, &:focus": {
            boxShadow: "none",
            background: "#fff",
          },
          "&i": {
            marginRight: "4px"
          },
        },
      },

    },
  },
});
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Grid style={webStyle.loginSec} container>
          <Grid style={webStyle.loginLeft} item lg={7} md={7} sm={6} xs={12} component={Box} display={{ xs: "none", lg: "block", md: "block", sm: "block" }}>
            <img src={loginLeftLogo} alt="" style={{ height: '70%' }} />
          </Grid>
          <Grid style={{ ...webStyle.loginRight, overflowY: "auto" }} item lg={5} md={5} sm={6} xs={12}>
            <Box style={webStyle.loginRightInner}>
              <Box sx={webStyle.loiginHeader}>
                <Box sx={webStyle.loginLogo}>
                  <img src={logo} alt="" />
                </Box>
                <Typography style={webStyle.welcomeHeader}>{i18n.t('Welcome_to_U1st')}</Typography>
                <Typography style={webStyle.subHeader}>
                  {i18n.t('Please_enter_your_details')}
                </Typography>
              </Box>

              <Box>
                <Box style={webStyle.textField}>
                  <label style={webStyle.textFieldLabel}>{i18n.t('Username_or_Email')}</label>
                  <Input
                    data-test-id="txtInputUsername"
                    placeholder={i18n.t('Username')}
                    fullWidth={true}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setUsername(e.target.value);
                      this.setState({ emailError: '' });
                    }}
                    type="text"
                    disableUnderline
                    onKeyPress={(e) => this.handleEnterKey(e)}
                  />
                  <Typography style={webStyle.errorStyle}>
                    {this.state.emailError}
                  </Typography>
                </Box>
                <Box>
                  <label style={webStyle.textFieldLabel}>{i18n.t('Password')}</label>
                  <Input
                    data-test-id="txtInputPassword"
                    disableUnderline
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={i18n.t('Password')}
                    fullWidth={true}
                    value={this.state.password}
                    onChange={(e) => {
                      this.setPassword(e.target.value);
                      this.setState({ passwordError: '' });
                    }}
                    onKeyPress={(e) => this.handleEnterKey(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                          data-test-id="eyeBtn"
                        >
                          <img src={this.state.enablePasswordField ? imgPasswordInVisible : imgPasswordVisible} alt="eye" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <Typography style={webStyle.errorStyle}>
                    {this.state.passwordError}
                  </Typography>
                </Box>
                <Box
                  data-test-id={"btnForgotPassword"}
                  sx={webStyle.forgotPasswordBtn}
                  onClick={() => this.goToForgotPassword()}
                >
                  {i18n.t('Forgot_Password')}
                </Box>
                {this.state.errorMessage && (
                  <Typography style={webStyle.errorStyle}>
                    {this.state.errorMessage}
                  </Typography>
                )}

                <Button data-test-id={"btnEmailLogIn"} onClick={() => this.doEmailLogIn()} variant="contained">
                  {i18n.t('Sign_In')}
                </Button>
              </Box>
              <Box style={webStyle.loginWith}>
                <Box style={{ ...webStyle.loginWithContainer as React.CSSProperties, color: this.state.loader ? "rgb(185 179 179)" : "#DCDCDC" }}>
                  <Typography variant="caption" component={"p"} style={webStyle.orSignUpText}>
                    <Box style={{ background: "#fff", ...webStyle.lineStyle as React.CSSProperties }} component={"span"}>
                      {i18n.t('or_Sign_In_with')}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Button
                className="microsoft-btn"
                variant="contained"
                data-test-id={"btnSocialLogin"}
                onClick={this.handleLoginPopup}
              >
                <i style={webStyle.iconStyle}>
                  <img src={mircosoftLogo} alt="" />
                </i>
                {i18n.t('Microsoft')}
              </Button>
              <Box sx={webStyle.microSoftBtn}>
                <Box sx={webStyle.linkLableStyle}>
                  {i18n.t('Dont_have_an_account')}
                  <Typography
                    style={webStyle.linkStyle}
                    component={"span"}
                    onClick={() => this.goToSignUp()}
                    data-test-id={"btnSignUp"}
                  >
                    {i18n.t('Sign_Up')}
                  </Typography>
                  <Box sx={webStyle.linkLableStyle}>
                    {i18n.t('By_Signing_In_you_agree_to')}
                    <Typography
                      data-test-id="privacy-policy"
                      style={webStyle.linkStyle}
                      onClick={this.setPrivacyPolicyModal}
                      component={"span"}
                    >
                      {i18n.t('Privacy_Policy')}
                    </Typography>
                  </Box>
                  <CustomDialog open={this.state.isPrivacyPolicy}>
                    <PrivacyPolicy navigation={this.props.navigation} id={""} setPrivacyPolicyModal={this.setPrivacyPolicyModal} />
                  </CustomDialog>
                </Box>
              </Box>
            </Box>

          </Grid>
        </Grid>
        <Loader loading={this.state.loader} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  orSignUpText: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '18px',
    margin: '0px',
    color: '#DCDCDC'
  },
  loginSec: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    width: "100%",
  },
  loginLeft: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${loginBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  loginRight: {
    background: "#fff",
    width: "100%",
    padding: "20px 20px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: 'center',
    height: '100vh',
  },
  loginRightInner: {
    fontFamily: "ClotherRegular",
  },
  loiginHeader: { marginBottom: "42px", textAlign: "center" },
  loginLogo: {
    marginBottom: "50px",
    width: "100%",
    height: '106px',
  },
  welcomeHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '26px',
    lineHeight: '24px',
    paddingBottom: '16px',
  },
  subHeader: {
    fontFamily: 'ClotherRegular',
    fontSize: '18px',
    lineHeight: '18px',
    color: '#A1A1A1',
  },
  textField: {
    width: "100%",
    marginBottom: "18px",
  },
  textFieldLabel: {
    display: "block",
    marginBottom: "8px",
    color: "#18181E",
    fontSize: "16px",
    lineHeight: "18px",
  },
  loginWith: {
    width: "100%",
    padding: "20px 0",
  },
  microSoftBtn: {
    marginBottom: "0px",
    width: "100%",
    textAlign: "center"
  },
  forgotPasswordBtn: {
    fontFamily: 'ClotherBold',
    color: "#8735E1",
    textAlign: "right",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "18px",
    marginBottom: "18px",
    cursor: "pointer",
  },
  linkLableStyle: {
    color: "#A1A1A1",
    fontSize: "16px",
    lineHeight: "18px",
    marginBottom: "18px",
  },
  linkStyle: {
    fontWeight: 700,
    color: "#8735E1",
    marginLeft: "4px",
    cursor: "pointer",
  },
  errorStyle: {
    color: "#F30202",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: "18px",
    maxWidth: "385px",
  },
  lineStyle: {
    display: "inline-block",
    position: "relative",
    zIndex: "2",
    padding: "0 12px"
  },
  loginWithContainer: {
    fontSize: "14px",
    lineHeight: "18px",
    position: "relative",
    textAlign: "center"
  },
  iconStyle: {
    marginRight: "4px"
  }
};

const CustomDialog = withStyles({
  root: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '600px',
      paddingBottom: '30px',
    },
  }
})(Dialog)
// Customizable Area End