import {
  Box,
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ChildProps, EditSteps } from "./types";
import { KeyboardArrowDown } from "@material-ui/icons";
import { webStyles } from "../../../../components/src/styles";
import i18n from 'i18next';

const SelectField = ({
  label,
  name,
  value,
  options,
  handleChange,
  handleBlur,
  apiFunction,
  errors,
  touched
}: any) => (
  <>
    <label style={webStyles.textFieldLabel}>{label}</label>
    <Select
      data-test-id="employee_type"
      style={{ ...webStyles.input, ...webStyles.inputStd }}
      IconComponent={KeyboardArrowDown}
      value={value}
      displayEmpty
      renderValue={
        value
          ? undefined
          : () => <div style={{ color: "#DCDCDC" }}>{i18n.t("Select")} {label}</div>
      }
      disableUnderline
      name={name}
      onChange={(event) => {
        handleChange(event);
        apiFunction && apiFunction(event.target.value);
      }}
      onBlur={handleBlur}
      MenuProps={{
        getContentAnchorEl: null,
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        PaperProps: {
          style: {
            maxHeight: "200px",
            width: "250px",
          },
        },
      }}
    >
      {options.map((option: any, index: any) => (
        <MenuItem key={index} value={option.attributes.id}>
          {option.attributes.name}
        </MenuItem>
      ))}
    </Select>
  </>
);

export const EditStep2 = ({
  handleChange,
  handleBlur,
  submitForm,
  errors,
  values,
  touched,
  setFieldTouched,
  setFieldValue,
  selectEmployeType,
  selectLocation,
  selectRole,
  selectFunction,
  selectSubFunction,
  selectWorkLocation,
  selectCountrie,
  setStep,
  changleScemaBack,
  functionAPI,
  subFunctionAPI,
  subWorkLocationAPI,
  locationAPI,
}: ChildProps) => (
  <>
    <Grid item xs={12} sm={6} data-id="step2">
      <SelectField
        label={i18n.t("Employee Type")}
        name="employee_type"
        value={values.employee_type}
        options={selectEmployeType}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        data-test-id="employee"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t("Contractor Name")}</label>
      <Input
        placeholder={i18n.t("Contractor Name")}
        fullWidth={true}
        type="text"
        disableUnderline
        name="contractor_name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.contractor_name}
        data-id="contractor_name"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <SelectField
        label={i18n.t("Role")}
        name="role"
        value={values.role}
        options={selectRole}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t('Country')}</label>
      <Select
        data-id="Countrie"
        style={
          { ...webStyles.input, ...webStyles.inputStd } as React.CSSProperties
        }
        IconComponent={KeyboardArrowDown}
        value={values.Countrie}
        displayEmpty
        renderValue={
          values.Countrie
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                Select Country
              </div>
            )
        }
        disableUnderline
        name="Countrie"
        onChange={(event: any) => {
          handleChange(event);
          console.log(event.target.value, 'event.target.value')
          locationAPI(event.target.value);
          setFieldValue("location", "");
          setFieldValue("function", "");
          setFieldValue("subFunction", "");
          setFieldValue("work_location", "");
        }}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {selectCountrie.map((countrie, countriId) => (
          <MenuItem key={countriId} value={countrie.id}>
            {countrie.attributes.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t("Location")}</label>
      <Select
        data-id="location"
        style={
          { ...webStyles.input, ...webStyles.inputStd } as React.CSSProperties
        }
        IconComponent={KeyboardArrowDown}
        value={values.location}
        displayEmpty
        renderValue={
          values.location
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                Location Type
              </div>
            )
        }
        disableUnderline
        name="location"
        onChange={(event: any) => {
          handleChange(event);
          functionAPI(event.target.value);
        }}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {selectLocation.map((location, index) => (
          <MenuItem key={index} value={location.id}>
            {location.attributes.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t("Function")}</label>
      <Select
        data-id="function"
        name="function"
        style={
          { ...webStyles.input, ...webStyles.inputStd } as React.CSSProperties
        }
        IconComponent={KeyboardArrowDown}
        value={values.function}
        displayEmpty
        renderValue={
          values.function
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                Function Type
              </div>
            )
        }
        disableUnderline
        onChange={(event: any) => {
          handleChange(event);
          subFunctionAPI(event.target.value);
        }}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {selectFunction.map((functionType, id) => (
          <MenuItem key={id} value={functionType.attributes.id}>
            {functionType.attributes.name}
          </MenuItem>
        ))}
      </Select>

    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t("Sub Function")}</label>
      <Select
        data-id="subFunction"
        style={
          { ...webStyles.input, ...webStyles.inputStd } as React.CSSProperties
        }
        IconComponent={KeyboardArrowDown}
        value={values.subFunction}
        displayEmpty
        renderValue={
          values.subFunction
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                Sub Function Type
              </div>
            )
        }
        disableUnderline
        name="subFunction"
        onChange={(event: any) => {
          handleChange(event);
          subWorkLocationAPI(event.target.value);
        }}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >
        {selectSubFunction.map((subfunctionType, index) => (
          <MenuItem key={index} value={subfunctionType.attributes.id}>
            {subfunctionType.attributes.name}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item xs={12} sm={6}>
      <label style={webStyles.textFieldLabel}>{i18n.t("Work Location / Operation")}</label>
      <Select
        data-id="work_location"
        name="work_location"
        style={{ ...webStyles.input, ...webStyles.inputStd }}
        IconComponent={KeyboardArrowDown}
        value={values.work_location}
        disableUnderline
        displayEmpty
        renderValue={
          values.work_location
            ? undefined
            : () => (
              <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                Work Location Type
              </div>
            )
        }
        onChange={(event) => {
          handleChange(event);
          setFieldValue("work_location_desc", "")
        }}
        onBlur={handleBlur}
        MenuProps={{
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            style: {
              maxHeight: "200px",
              width: "250px",
            },
          },
        }}
      >

        {selectWorkLocation.map((workLocation, id) => (
          <MenuItem value={workLocation.id} key={id}>
            {workLocation.attributes.name}
          </MenuItem>
        ))}

      </Select>
      {values.work_location === "Other" && (
        <>
          <textarea
            style={{
              borderRadius: "7px",
              padding: "15px",
              width: " 100%",
              height: "100px",
              border: "1px solid #d5d5d5",
              outline: "none",
              marginTop: '15px'
            }}
            name="work_location_desc"
            placeholder="Other work Location / operation"
            value={values.work_location_desc}
            onChange={handleChange}
            onBlur={handleBlur}
            data-id="work_location_desc"
          />
        </>
      )}
    </Grid>

    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      padding="0.75rem"
      style={webStyles.footer}
    >
      <Button
        style={{ ...webStyles.transButton, width: "15%", borderRadius: "8px" }}
        data-id="backToPrevStep"
        onClick={() => {
          setStep(EditSteps.Step1);
          changleScemaBack && changleScemaBack(1);
        }}
      >
        <Typography style={webStyles.buttonStyle as React.CSSProperties}>
          {i18n.t("Previous")}
        </Typography>
      </Button>
      <Button
        style={{ ...webStyles.fullButton, width: "15%", borderRadius: "8px" }}
        data-id="nextBtnStep2"
        type="submit"
        onClick={submitForm}
      >
        <Typography style={webStyles.buttonStyle as React.CSSProperties}>
          {" "}
          {i18n.t("NEXT")}
        </Typography>
      </Button>
    </Box>
  </>
);
