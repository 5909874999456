import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NavigateNext } from "@material-ui/icons";
import i18n from "i18next"

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

import PointsHistoryController, { Props } from "./PointsHistoryController";
// Customizable Area End

export default class PointsHistory extends PointsHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.wrapper}>
                    <Container style={webStyle.pointsHistoryMainWrapper} maxWidth={"xl"}>
                        <Box style={webStyle.pointsHistoryProfileWrapper}>
                            <Typography style={webStyle.pointsHistoryProfileText}>{i18n.t('Profile')}</Typography>
                            <NavigateNext style={{ fill: '#DCDCDC'}} />
                            <Typography
                                style={webStyle.pointsHistoryProfileText}
                                data-test-id="gotoRewards"
                                onClick={() => { this.gotoRewardsPage() }}>
                                {i18n.t('Rewards')}
                            </Typography>
                            <NavigateNext style={{ fill: '#DCDCDC' }} />
                            <Typography style={webStyle.mainHeading}>
                                {i18n.t('Point History')}
                            </Typography>
                        </Box>
                        <Typography style={webStyle.pointsHistoryHeading}>
                            {i18n.t('Point History')}
                        </Typography>

                        <Box sx={webStyle.pointsHistoryMainAutoBox}>
                            <Box style={webStyle.poinstRow}>
                                <Box>
                                    <Box style={webStyle.pointsCardRow}>
                                        <Box>
                                            <Typography style={webStyle.pointsTitle}>
                                                {i18n.t('You liked Jason’s Post')}
                                            </Typography>
                                            <Typography style={webStyle.pointsDate}>30/10/2023</Typography>
                                        </Box>
                                        <Typography align="right" style={webStyle.pointsCount}>50 {i18n.t('points')}</Typography>
                                    </Box>
                                    <Box style={webStyle.pointsCardRow}>
                                        <Box>
                                            <Typography style={webStyle.pointsTitle}>
                                                {i18n.t('Reported a Fire Concern')}
                                            </Typography>
                                            <Typography style={webStyle.pointsDate}>26/10/2023</Typography>
                                        </Box>
                                        <Typography align="right" style={webStyle.pointsCount}>150 {i18n.t('points')}</Typography>
                                    </Box>
                                    <Box style={webStyle.pointsCardRow}>
                                        <Box>
                                            <Typography style={webStyle.pointsTitle}>
                                                {i18n.t('Commented on John’s Post')}
                                            </Typography>
                                            <Typography style={webStyle.pointsDate}>26/10/2023</Typography>
                                        </Box>
                                        <Typography align="right" style={webStyle.pointsCount}>50 {i18n.t('points')}</Typography>
                                    </Box>
                                    <Box style={webStyle.pointsCardRow}>
                                        <Box>
                                            <Typography style={webStyle.pointsTitle}>
                                                {i18n.t('Completed Road Safety Course')}
                                            </Typography>
                                            <Typography style={webStyle.pointsDate}>22/10/2023</Typography>
                                        </Box>
                                        <Typography align="right" style={webStyle.pointsCount}>50 {i18n.t('points')}</Typography>
                                    </Box>
                                    <Box style={webStyle.pointsCardRow}>
                                        <Box>
                                            <Typography style={webStyle.pointsTitle}>
                                                {i18n.t('You added Risk Level to Profile')}
                                            </Typography>
                                            <Typography style={webStyle.pointsDate}>10/10/2023</Typography>
                                        </Box>
                                        <Typography align="right" style={webStyle.pointsCount}>50 {i18n.t('points')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    navigateIcon: {
        fill: '#DCDCDC'
    },
    wrapper: {
        width: '100%',
        backgroundColor: 'lightgray',
        padding: '20px 0',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto',
        fontFamily: "ClotherRegular"

    },
    pointsHistoryHeading: {
        margin: '20px 0',
        textEdge: "cap",
        color: "#2B2A29",
        fontSize: "26px",
        leadingTrim: "both",
        fontWeight: 700,
        marginBottom: '30px',
        lineHeight: "18px",
        fontStyle: "normal",
        fontFamily: "ClotherBold"
    },
    pointsHistoryMainWrapper: {
        padding: '0 !important'
    },
    pointsHistoryMainAutoBox: {
        background: '#fff',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',
    },
    pointsHistoryProfileWrapper: {
        display: 'flex',
        // gap: '10px'
    },
    pointsHistoryProfileText: {
        fontWeight: 700,
        textEdge: "cap",
        fontFamily: 'ClotherRegular',
        fontSize: "16px",
        lineHeight: '22px',
        paddingBottom: '17px',
        fontStyle: "normal",
        cursor: "pointer",
        leadingTrim: "both",
        color: "#A1A1A1",
    },
    mainHeading: {
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherRegular"

    },
    poinstRow: {
        width: '100%',
    },
    pointsCardRow: {
        padding: '20px 30px',
        borderBottom: '1px solid lightgray',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    pointsTitle: {
        color: "#2B2A29",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        fontFamily: "ClotherRegular"
    },
    pointsDate: {
        color: "#A1A1A1",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        fontFamily: "ClotherRegular"
    },
    pointsCount: {
        color: "#21C274",
        textAlign: "right" as "right",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "ClotherBold"
    },
};
// Customizable Area End
