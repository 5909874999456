import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { locationIcon, timeIcon } from "./assets";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import PerformanceTrackerController, {
  Props, configJSON,
} from "./PerformanceTrackerController";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import { webStyles } from "../../../components/src/styles";
import { searchDefaultImage } from "../../dashboard/src/assets";
import i18n from "i18next"
// Customizable Area End

export default class ActionTrackerPost extends PerformanceTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  errorMessageDisplay = () => {
    return (
      <Box display={"none"} color={"red"} fontSize={"12px"}>
        <Typography>{i18n.t('Unable to fetch data! Please try again.')}</Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { loaderData, is_supervisor, actionPostData } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid style={webStyle.mainContainer}>
          <Box style={webStyles.breadcrumbWrapper}>
            <Typography style={webStyles.breadcrumbItem} onClick={this.goToActionTracker}>
              {i18n.t(configJSON.ActiontrackerText)}
            </Typography>
            <NavigateNext style={webStyles.navigateIcon} />
            <Typography style={webStyles.breadcrumbActiveItem} onClick={this.goToPostPage}>
              {i18n.t(configJSON.PostText)}
            </Typography>
          </Box>
          <Typography style={webStyle.header}>{i18n.t(configJSON.PostText)}</Typography>
          {!loaderData && actionPostData?.id && (
            <>
              <Paper style={webStyle.paperStyle}>
                <Grid>
                  <Typography style={webStyle.firstTypography} data-test-id="profileImgText" onClick={() => this.goToUserProfile(actionPostData.attributes.account_id)}>
                    <Avatar data-test-id="profileImg" src={this.getProfileImage(actionPostData) || searchDefaultImage} style={webStyle.profileImage} />
                    <span style={webStyle.fullName as React.CSSProperties}>{this.getPostUserName(actionPostData)} {i18n.t('CreatedthispostText')}</span>
                  </Typography>
                  <img src={this.getPostImage(actionPostData)}
                    alt="Your Image"
                    style={webStyle.postImage}
                    data-test-id="postImage"
                  />
                  {actionPostData.attributes.description && (
                    <Typography style={webStyle.postDescribtion}>
                      {actionPostData.attributes.description}
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                    {actionPostData.attributes?.checkpoint?.unit_of_measurement &&
                      <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.areaBox}>
                        <Typography style={webStyle.detailsHeading}>{i18n.t(configJSON.UnitOfMeasurementText)}:</Typography>
                        <Typography style={webStyle.detailsText} className='action_description'>{actionPostData.attributes.checkpoint.unit_of_measurement}</Typography>
                      </Grid>
                    }
                    {actionPostData.attributes?.checkpoint?.remark &&
                      <Grid item lg={12} md={12} sm={12} xs={12} style={webStyle.areaBox}>
                        <Typography style={webStyle.detailsHeading}>{i18n.t(configJSON.RemarksText)}:</Typography>
                        <Typography style={webStyle.detailsText} className='action_description'>{actionPostData.attributes.checkpoint.remark}</Typography>
                      </Grid>
                    }
                    {actionPostData.attributes.area &&
                      <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.areaBox}>
                        <Typography style={webStyle.detailsHeading}>{i18n.t(configJSON.AreaText)}:</Typography>
                        <Typography style={webStyle.detailsText} className='action_description'>{actionPostData.attributes.area}</Typography>
                      </Grid>
                    }
                    {actionPostData.attributes.serial_number &&
                      <Grid item lg={6} md={6} sm={6} xs={12} style={webStyle.areaBox}>
                        <Typography style={webStyle.detailsHeading}>{i18n.t(configJSON.SrNumberText)}:</Typography>
                        <Typography style={webStyle.detailsText} className='action_description'>{actionPostData.attributes.serial_number}</Typography>
                      </Grid>
                    }
                    {actionPostData.attributes.location_description &&
                      <Grid item lg={12} style={webStyle.areaBox}>
                        <Typography style={webStyle.detailsHeading}>{i18n.t(configJSON.LocationDescriptionText)}:</Typography>
                        <Typography style={webStyle.detailsText} className='action_description'>{actionPostData.attributes.location_description}</Typography>
                      </Grid>
                    }
                  </Grid>
                  <Grid container style={{ marginTop: "20px" }}>
                    <Typography style={webStyle.locationTypography as React.CSSProperties}>
                      <img src={locationIcon} style={webStyle.locationIcon} />
                      <span style={webStyle.locationText as React.CSSProperties}>{this.getPostLocation(actionPostData)}</span>
                    </Typography>
                    <Typography data-test-id="profileImgText2" style={webStyle.usernameTimestyle} onClick={() => this.goToUserProfile(actionPostData.attributes.account_id)}>
                      <Avatar src={this.getProfileImage(actionPostData) || searchDefaultImage} style={webStyle.bottomProfileImg} />
                      <span style={webStyle.locationText as React.CSSProperties}>{this.getPostUserName(actionPostData)}</span>
                    </Typography>
                    <Typography style={webStyle.usernameTimestyle}>
                      <img src={timeIcon} style={webStyle.timeIcon} />{getTimeFromNow(actionPostData.attributes.created_at)} {i18n.t('ago')}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Box style={webStyle.btnDiv}>
                {is_supervisor ? (
                  <>
                    {(actionPostData.attributes.status !== "verified" && actionPostData.attributes.status !== "Pending review") && (
                      <Button
                        data-test-id="reAssignPage"
                        variant="outlined"
                        color="secondary"
                        style={webStyle.btnStyle as React.CSSProperties}
                        onClick={this.navigatePage.bind(this, "reAssign")}
                      >
                        {i18n.t(configJSON.ReAssignText)}
                      </Button>
                    )}
                    {!(is_supervisor && actionPostData.attributes.status === "Pending review") ? <Button
                        variant="outlined"
                        color="primary"
                        style={webStyle.btnStyleColor as React.CSSProperties}
                        data-test-id="followUpPage"
                        onClick={this.navigatePage.bind(this, "followUp")}
                      >
                        {i18n.t(" Follow Up")}
                      </Button>
                     : 
                        <Button
                          variant="outlined"
                          color="primary"
                          style={webStyle.btnStyleColor as React.CSSProperties}
                          data-test-id="followUpPage"
                          onClick={this.navigatePage.bind(this, "followUp")}
                        >
                          {i18n.t("Review Taken Action")}
                        </Button>}
                  </>
                ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      style={webStyle.btnStyleColor as React.CSSProperties}
                      data-test-id="updateBtn"
                      onClick={this.navigatePage.bind(this, "update")}
                    >
                      {i18n.t("Update")}
                    </Button>
                  )}
              </Box>
            </>
          )}

        </Grid>
        <Loader loading={this.state.loaderData} />
        {this.errorMessageDisplay()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  navigateIcon: {
    fill: '#DCDCDC'
  },
  mainContainer: {
    padding: "30px",
    background: "rgb(238, 238, 238)"
  },
  areaBox: {
    height: '68px',
    borderRadius: '8px',
    background: '#F8F8F8',
  },
  detailsHeading: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2B2A29'
  },
  detailsText: {
    fontFamily: 'ClotherBold',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#2B2A29'
  },
  locationText: {
    overflow: "hidden",
    maxWidth: "130px",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
  },
  timeIcon: {
    width: '24px',
    height: '24px',
    marginRight: '6px',
  },
  bottomProfileImg: {
    width: '26px',
    height: '26px',
    border: '1px',
    marginRight: '6px',
  },
  postDescribtion: {
    fontFamily: 'ClotherRegular',
    fontSize: '18px',
    lineHeight: '22px',
    paddingBottom: '20px',
  },
  postImage: {
    width: '100%',
    height: '380px',
    margin: '20px 0px',
    borderRadius: '8px',
    objectFit: 'cover' as const,
  },
  profileImage: {
    width: '46px',
    height: '46px',
    marginRight: '16px',
  },
  header: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: '24px',
    color: '#2B2A29',
    padding: '0px 0px 20px 0px',
    marginTop: '17px',
  },
  currentPage: {
    fontFamily: 'ClotherBold',
    fontSize: '14px',
    color: '#8735E1',
    marginLeft: '2px',
    cursor: 'pointer',
  },
  actionTrackerHeaders: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#18181E',
    paddingBottom: '17px',
    margin: '0px',
    cursor: 'pointer'
  },
  locationIcon: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
  },
  locationTypography: {
    alignItems: "center",
    display: "flex",
    borderRadius: "6px",
    background: "#F7F3F3",
    whiteSpace: "nowrap",
    color: "#A1A1A1",
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    padding: "5px 15px",
    marginRight: '20px',
  },
  btnStyleColor: {
    minWidth: "160px",
    height: "50px",
    backgroundColor: "#8735E1",
    textTransform: "none",
    color: "white",
    border: "1px solid #8735E1",
    padding: '10px',
    borderRadius: "8px",
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
  },
  paperStyle: {
    height: "auto",
    padding: "30px",
    background: "#fff",
    minHeight: "80vh",
    marginTop: '10px',
    borderRadius: '8px',
  },
  btnStyle: {
    minWidth: "160px",
    height: "50px",
    textTransform: "none",
    borderColor: "rgb(135, 53, 225)",
    border: "1px solid #8735E1",
    color: "#8735E1",
    fontFamily: 'ClotherBold',
    fontSize: "20px",
    lineHeight: '22px',
    backgroundColor: "white",
    borderRadius: '8px',
    marginRight: '16px',
  },
  btnDiv: {
    padding: "30px 0px",
    display: "flex",
    justifyContent: "flex-end",
  },
  firstTypography: {
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    display: "flex",
    alignItems: "center",
    cursor: 'pointer'
  },
  fullName: {
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
  },
  usernameTimestyle: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    display: "flex",
    alignItems: "center",
    color: '#A1A1A1',
    marginRight: '20px',
  },
};
// Customizable Area End
