import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    Typography,
    InputAdornment,
    // Customizable Area Start
    styled,
    Grid,
    Select,
    InputBase,
    MenuItem,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { calendarLogo } from "./assets";
import i18n from "i18next"

const today = new Date()

// Customizable Area End

import AddMachineController, {
    Props,
    configJSON,
} from "./AddMachineController";

export default class AddMachine extends AddMachineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    CustomInputDate = (
        value: Date | null | undefined,
        onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
        return (
            <Input
                placeholder="Select Date"
                value={value}
                onClick={onClick}
                fullWidth={true}
                disableUnderline
                data-test-id="date_test"
                autoComplete="off"
                name="date_test"
                onChange={onChange}
                defaultValue={""}
                endAdornment={
                    <div>
                        <InputAdornment position="end">
                            <img src={calendarLogo} className="dateIcon" />
                        </InputAdornment>
                    </div>
                }
            />
        );
    }

    addMachineForm = () => {
        const { equipmentName, equipmentNumber, locations, selectedLocation, dueDate, categories, selectedCategory, addMachineClicked } = this.state;
        return (
            <Box className="mainBox">
                <Grid md={12} className="mainGrid">
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">{i18n.t('equipment_name')}</Typography>
                        <Input
                            name="equipmentName"
                            data-test-id="equipment_name"
                            className="enterInputField"
                            value={equipmentName}
                            disableUnderline
                            placeholder={i18n.t('equipment_name')}
                            autoComplete="off"
                            onChange={this.handleChangeEquipmentName}
                        />
                        <Typography className="errorStyle">
                            {addMachineClicked && !equipmentName && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">{i18n.t('equipment_number')}</Typography>
                        <Input
                            name="equipmentNumber"
                            data-test-id="equipment_number"
                            className="enterInputField"
                            value={equipmentNumber}
                            disableUnderline
                            placeholder={i18n.t('equipment_number')}
                            autoComplete="off"
                            onChange={this.handleChangeEuipmentNumber}
                        />
                        <Typography className="errorStyle">
                            {addMachineClicked && !equipmentNumber && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box className="locationBox">
                            <Typography className="inputLabel">
                                {i18n.t('location')}
                            </Typography>
                        </Box>
                        <Select
                            data-test-id="location"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedLocation}
                            displayEmpty
                            renderValue={
                                selectedLocation
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 18 } as React.CSSProperties}>
                                            {i18n.t('location_details')} {/* Translates 'Location Details' */}
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeLocation}
                            input={<InputBase />}
                        >
                            {locations.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {addMachineClicked && !selectedLocation && configJSON.errorMsg}
                        </Typography>
                    </Grid>
                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">{i18n.t('due_date')}</Typography> {/* Translates 'Due Date' */}
                        <DatePicker
                            className="field-input"
                            scrollableYearDropdown
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            dateFormat="dd/MM/yyyy"
                            dateFormatCalendar="MMMM"
                            data-test-id="due_date"
                            name="due_date"
                            autoComplete="off"
                            placeholderText={i18n.t('due_date')}
                            minDate={today}
                            wrapperClassName="date-picker"
                            customInput={this.CustomInputDate(dueDate, () => { }, this.handleChangeDueDate)}
                            popperClassName="example-datepicker-class"
                            selected={dueDate}
                            onChange={(date) => {
                                this.handleChangeDueDatePicker(date);
                            }}
                        />
                        <Typography className="errorStyle">
                            {addMachineClicked && !dueDate && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box className="locationBox">
                            <Typography className="inputLabel">
                                {i18n.t('category')} 
                            </Typography>
                        </Box>
                        <Select
                            data-test-id="categories"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedCategory}
                            displayEmpty
                            renderValue={
                                selectedCategory
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 18 } as React.CSSProperties}>
                                            {i18n.t('select_category')}
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeCategory}
                            input={<InputBase />}
                        >
                            {categories.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {addMachineClicked && !selectedCategory && configJSON.errorMsg}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Wrapper>
                        <Container maxWidth={"xl"}>
                            <Box className="headingWrapper">
                                <Typography
                                    className="subHeadingText"
                                    data-test-id="navigate_to_audit_screen"
                                    onClick={this.navigateToAuditScreen}>
                                    {i18n.t('audit')}
                                </Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText activeHeadingText">
                                    {this.state.editMachine ? i18n.t('update_machine') : i18n.t('add_new_machine')}
                                </Typography>
                            </Box>
                            <Box className="myPostsTitle">
                                <Typography className="mainHeading">
                                    {this.state.editMachine ? i18n.t('update_machine') : i18n.t('add_new_machine')}
                                </Typography>
                            </Box>
                            {this.addMachineForm()}
                            <Box className="submit-button">
                                <Button
                                    data-test-id="add_btn"
                                    variant="contained"
                                    className="add-btn"
                                    disabled={this.state.disableAddButton}
                                    onClick={this.handleAddButton}
                                >
                                    {this.state.editMachine ? i18n.t('update') : i18n.t('add')}
                                </Button>
                            </Box>
                            {this.state.loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                        </Container>
                    </Wrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    boxSizing: "border-box",
    padding: "20px 0 140px",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    position: "relative",
    "& .loader-outer": {
        backgroundColor: "rgb(0, 0, 0, .4)",
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        minHeight: "100vh",
        top: 0,
        right: 0,
        zIndex: 1,
    },
    "& .loader-inner": {
        transform: "translate(-50%, -50%)",
        position: "absolute",
        top: "40%",
        left: "50%",
    },
    "& .locationBox": {
        display: 'flex',
        justifyContent: 'space-between'
    },
    "& .subHeadingText": {
        color: "#A1A1A1",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        cursor: "pointer",
    },
    "& .headingWrapper": {
        display: "flex",
    },
    "& .enterInputField": {
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        fontSie: '18px',
        borderRadius: '8px',
        height: '50px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .inputLabel": {
        fontSize: '18px',
        fontWeight: 600,
        color: 'var(--Black, #18181E)',
        fontFamily: 'ClotherRegular',
        marginBottom: '5px'
    },
    "& .dateIcon": {
        width: 28,
        height: 28,
        cursor: 'pointer'
    },
    "& .activeHeadingText": {
        fontWeight: 700,
        color: "#8735E1",
        textEdge: "cap",
        fontSize: "16px",
        fontStyle: "normal",
        leadingTrim: "both",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        cursor: "pointer"
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },

    "& .mainHeading": {
        fontStyle: "normal",
        leadingTrim: "both",
        fontWeight: 700,
        lineHeight: "18px",
        margin: "20px 0",
        color: "#2B2A29",
        textEdge: "cap",
        fontSize: "26px",
        fontFamily: "ClotherBold",
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .myPostsTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0",
    },
    "& .field-input": {
        width: '100%',
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '50px',
        padding: '10px 15px',
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        padding: '10px 15px',
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '152px',
        width: '100%',
        outline: 'none'
    },
    "& .MuiInputBase-input": {
        height: "15px",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    "& .Location-input .MuiInputBase-input": {
        height: "14px",
    },
    "& .MuiInputBase-root": {
        fontFamily: "ClotherBold",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px"
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "0.85rem",
        marginBottom: "0.5rem",
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .inputBlock": {
        maxWidth: '48%',
        marginBottom: "10px",
        "@media (max-width: 600px)": {
            maxWidth: '100%',

        }
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .mainGrid": {
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        backgroundColor: 'white',
        borderRadius: "8px",
        width: "100%",
        margin: "15px 0px"
    },
    "& .add-btn": {
        boxShadow: "none",
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        height: "50px",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },

});

// Customizable Area End
